/* eslint-disable jsx-a11y/no-distracting-elements */
// @ts-nocheck
import React from "react";

const HeaderMarquee = ({ marquee }) => {
  return (
    <marquee
      className="font-monospace text-nowrap fw-bolder py-1"
      style={{ color: "white", fontSize: "16px" }}
    >
      {marquee}
    </marquee>
  );
};
export default HeaderMarquee;
