import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoginContext } from "../store/context/LoginProvider";
import DatePicker from "react-datepicker";
import Loading from "../components/shared_comp/Loading";
import { moneyFormatter } from "../utils/moneyFormatter";
import { useTranslation } from "react-i18next";
import { getReferralHistoryApi } from "../api/historyApi";
import { format } from "date-fns";

const ReferHistory = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("2d");
  const [history, setHistory] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // context
  const [{ userData }] = useContext(LoginContext);
  const { t } = useTranslation();

  const historyTypes = [
    {
      type: "1d",
      name: "1D Bet Slips",
    },
    {
      type: "2d",
      name: "2D Bet Slips",
    },
    {
      type: "c1d",
      name: "BTC 1D Bet Slips",
    },
    {
      type: "c2d",
      name: "BTC 2D Bet Slips",
    },
    {
      type: "3d",
      name: "3D Bet Slips",
    },
  ];

  useEffect(() => {
    const getHistory = async () => {
      setLoading(true);
      try {
        await getReferralHistoryApi({
          token: userData?.token,
          type: selected,
          from: startDate,
          to: endDate,
        })
          .then((res) => setHistory(res.data))
          .catch((err) =>
            console.error(err.response.data.message || "Something wrong")
          )
          .finally(() => setLoading(false));
      } catch (error) {
        throw new Error(error);
      }
    };

    getHistory();
  }, [endDate, selected, startDate, userData?.token]);

  return (
    <div className="w-100 mx-auto">
      <div className="text-center fw-bold text-white fs-3 mt-2">
        {t("refer_history")}
      </div>
      <div className="container mx-auto pt-2 mt-3 w-full glassThree rounded-3">
        {/* History Filter Section */}
        <div className="row d-flex justify-content-center mt-4">
          <div className="col-md-3 col-6 offset-md-0 offset-3">
            <select
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
              className="form-select form-select-sm py-2 px-3 rounded-5 border border-2 border-secondary bg-transparent glass text-white shadow-lg bg-opacity-75"
            >
              {historyTypes?.map(({ type, name }, _index) => (
                <option className="text-black" key={_index} value={type}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          {/* Start Date fff */}
          <div style={{ zIndex: "7" }} className="col-md-3 col-6 mt-md-0 mt-3">
            <DatePicker
              className="form form-control rounded-5 bg-opacity-75 border border-2 border-secondary bg-transparent text-white shadow-lg"
              selected={startDate}
              onChange={setStartDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          {/* End Date */}
          <div style={{ zIndex: "7" }} className="col-md-3 col-6 mt-md-0 mt-3">
            <DatePicker
              className="form form-control rounded-5 glass bg-transparent border border-2 border-secondary text-white shadow-lg"
              selected={endDate}
              onChange={setEndDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
        </div>
        {/* History Section */}
        {loading ? (
          <div className="py-3">
            <Loading loading={loading} />
          </div>
        ) : (
          <div className="row mt-2" style={{ overflowX: "auto" }}>
            <table className="table mt-2 " style={{ minWidth: "700px" }}>
              <thead>
                <tr className="glassThree text-white text-center">
                  <th>{t("profilePage.userName")}</th>
                  <th>{t("referralPage.amount")}</th>
                  <th>{t("betslipPage.section")}</th>
                  <th>{t("betslipPage.date")}</th>
                </tr>
              </thead>
              <tbody className="glassFour text-white text-center">
                {history.length ? (
                  <Fragment>
                    {history.map((h) => {
                      return (
                        <tr>
                          <td>{h.user.name}</td>
                          <td>{h.amount}</td>
                          <td>{h.bet_date_3d || h.section}</td>
                          <td>{format(h.created_at, "yyyy-MM-dd")}</td>
                        </tr>
                      );
                    })}
                  </Fragment>
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <h6 className="text-white py-3">No Data!</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferHistory;
