// @ts-nocheck
import React from "react";
import payment from "../asset/image/payment.png";
import ProviderSwiper from "./ProviderSwiper";
const MainFooter = ({ socialLink }) => {
  return (
    <div className="w-100 bg-black bg-opacity-25 active">
      <div className="d-none d-md-flex container align-items-center">
        <div className="text-muted small pt-5 pb-4 w-50">
          <span className="fw-bolde text-white fs-6">Powered by NGWE TOE</span>
          <br /> offers state-of-the-art technology that has innovated,
          <br /> Asia's best gaming products and games that has rapidly become
          higher in class.
          <br /> 2024 NGWE TOE. All rights reserved.
        </div>
        <div className="w-50">
          <img className="w-100" src={payment} alt="payment" />
        </div>
      </div>
      <ProviderSwiper />
      <footer className="bg-dark">
        <div className="container py-2 d-flex text-white small gap-5">
          <div>© NGWE TOE All Rights Reserved.</div>
          <div className="d-none d-md-block">Terms & Condition</div>
          <div className="d-none d-md-block">Privacy Policy</div>
          <div className="d-none d-md-block">Responsible Gambling</div>
        </div>
      </footer>
    </div>
  );
};

export default MainFooter;
