// @ts-nocheck
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo_1 from "../../src/asset/image/providers/logo_1.png";
import Logo_2 from "../../src/asset/image/providers/logo_2.png";
import Logo_3 from "../../src/asset/image/providers/logo_3.png";
import Logo_4 from "../../src/asset/image/providers/logo_4.png";
import Logo_5 from "../../src/asset/image/providers/logo_5.png";
import Logo_6 from "../../src/asset/image/providers/logo_6.png";
import Logo_7 from "../../src/asset/image/providers/logo_7.png";
import Logo_8 from "../../src/asset/image/providers/logo_8.png";
import Logo_9 from "../../src/asset/image/providers/logo_9.png";
import Logo_10 from "../../src/asset/image/providers/logo_10.png";
import Logo_11 from "../../src/asset/image/providers/logo_11.png";
import Logo_12 from "../../src/asset/image/providers/logo_12.png";
import Logo_13 from "../../src/asset/image/providers/logo_13.png";
import Logo_14 from "../../src/asset/image/providers/logo_14.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";

// import required modules
import { Autoplay, Navigation } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ProviderSwiper() {
  let providerLinks = [
    Logo_1,
    Logo_2,
    Logo_3,
    Logo_4,
    Logo_5,
    Logo_6,
    Logo_7,
    Logo_8,
    Logo_9,
    Logo_10,
    Logo_11,
    Logo_12,
    Logo_13,
    Logo_14,
  ];

  return (
    <div className="container d-none d-lg-flex mx-auto mb-2">
      <Swiper
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        slidesPerView={3}
        breakpoints={{
          768: { slidesPerView: 8, spaceBetween: 1 },
        }}
        loop={true}
        spaceBetween={1}
        modules={[Navigation, Autoplay]}
        className="mySwiper"
      >
        {providerLinks.map((data, idx) => (
          <SwiperSlide key={idx}>
            <LazyLoadImage width={75} src={data} effect="blur" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ProviderSwiper;
