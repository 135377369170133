// @ts-nocheck
import React from "react";

// swiper - css
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Footer({ socialLink }) {
  return (
    <div className="pb-2 animation_left rounded-start d-flex justify-content-center align-items-center">
      {/* Partner */}
      {/* Footer */}

      <div
        className="fw-bold text-secondary small fst-italic"
        style={{
          writingMode: "vertical-lr",
          transform: "scale(-1,-1)",
          letterSpacing: ".15rem",
        }}
      >
        Customer Support
      </div>
      <div className="d-flex flex-column align-items-end gap-3 ps-1">
        {/* FB  */}
        <a
          href={socialLink?.facebook}
          className="text-decoration-none pe-3 text-primary football"
        >
          <i className="fa-brands fs-2 fa-facebook"></i>
        </a>

        {/* Messenger */}
        <a
          href={socialLink?.messenger}
          className="text-decoration-none pe-3 text-warning football"
        >
          <i className="fa-brands fs-2 fa-facebook-messenger"></i>
        </a>

        {/* Viber */}

        <a
          href={socialLink?.viber}
          className="text-decoration-none pe-3 text-danger football"
        >
          <i className="fa-brands fs-2 fa-viber"></i>
        </a>
        {/* Instagram */}

        <a
          href={socialLink?.instagram}
          className="text-decoration-none pe-3 text-info football"
        >
          <i className="fa-brands fs-2 fa-telegram"></i>
        </a>
      </div>
    </div>
  );
}

export default Footer;
