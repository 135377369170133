// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getTransitionDetail } from "../api/historyApi";
import { LoginContext } from "../store/context/LoginProvider";
import moment from "moment";
import Loading from "../components/shared_comp/Loading";
// context

const BetSlipDetail = () => {
  const { p_code } = useParams();
  const [{ userData }] = useContext(LoginContext);
  const [slips, setSlips] = useState([]);
  const [loading, setLoading] = useState(false);
  let { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getTransitionDetail({ token: userData?.token, p_code }).then((res) => {
      setSlips(res.data);
      setLoading(false);
    });
  }, [p_code, userData]);

  if (loading && !slips.length) return <Loading full={true} loading={true} />;

  return (
    <div className="row container mx-auto" style={{ overflowX: "auto" }}>
      <table
        className="table table-borderless glassThree mt-2 rounded-3"
        style={{ minWidth: "700px" }}
      >
        <thead>
          <tr className="text-white glassTwo text-center">
            <th>{t("betslipPage.wagerID")}</th>
            <th>{t("betslipPage.oldBalance")}</th>
            <th>{t("betslipPage.BALANCE")}</th>
            <th>{t("betslipPage.Game")}</th>
            <th>{t("Game.betamount")}</th>
            <th>{t("Game.payoutamount")}</th>
            <th>{t("Game.winLose")}</th>
            <th>{t("betslipPage.date")}</th>
          </tr>
        </thead>
        <tbody className="text-center" style={{ color: "#e0e0e0" }}>
          {slips.length ? (
            slips.map((slip) => (
              <tr key={slip.id}>
                <td>{slip.wager_id}</td>
                <td>{slip.before_balance}</td>
                <td>{slip.balance}</td>
                <td>{slip.slip_game ? slip.slip_game.name : slip.game}</td>
                <td>{slip.bet_amount}</td>
                <td>{slip.payout_amount}</td>
                <td
                  className={`fw-bold ${
                    slip.payout_amount - slip.bet_amount > 0
                      ? "text-success"
                      : slip.payout_amount - slip.bet_amount === 0
                      ? ""
                      : "text-danger"
                  }`}
                >
                  {slip.payout_amount - slip.bet_amount}
                </td>
                <td>{moment(slip.created_at).format("DD-MM-YYYY")}</td>
              </tr>
            ))
          ) : (
            <tr>no data</tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BetSlipDetail;
