// @ts-nocheck
import React, { useContext, useState } from "react";
// lib
import i18n from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// shared-comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// images
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import icon from "../../../src/asset/image/logos.png";
import { BaseURL } from "../../api/apiUrl";
// utils
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// config
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
//icons
import {
  UserCircleIcon,
  HomeIcon,
  BellAlertIcon,
} from "@heroicons/react/24/solid";

// context
import { LoginContext } from "../../store/context/LoginProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import { getProfileApi } from "../../api/profileApi";
const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({
  userData,
  handleToggle,
  handleLogout,
  isDisabled,
  marquee,
  loading,
}) => {
  let { t } = useTranslation();
  let { pathname } = useLocation();
  pathname = pathname.substring(1, pathname.length).split("/")[0];
  const navigate = useNavigate();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const [amount, setAmount] = useState(userData?.balance || 0);

  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) setAmount(res?.data?.balance);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // The tab is hidden
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userData]);
  return (
    <nav>
      <div className="d-none d-lg-flex justify-content-end justify-content-md-between align-items-start">
        {/* Lang Switch */}

        <section className="w-100 rounded-0">
          <div className="d-flex align-items-center container">
            <Link
              to="/"
              className="d-flex rounded-0 text-decoration-none text-white align-items-center"
            >
              <img
                src={icon}
                style={{
                  width: "5rem",
                  height: "5rem",
                  objectFit: "cover",
                }}
                alt="icon"
                className="rounded-circle"
              />
            </Link>

            <div className="w-100 px-5 d-flex align-items-baseline">
              {/* Announcement Icon */}

              {marquee && (
                <BellAlertIcon
                  width={30}
                  style={{ transform: "translateY(-6.5%)" }}
                />
              )}

              {/** marquee */}
              <div className="w-100">
                <HeaderMarquee marquee={marquee} />
              </div>
            </div>

            {/** button & profile */}
            <div className="">
              {userData?.token ? (
                <div
                  className="d-flex rounded-2 flex-row ps-1 me-1 justify-content-between align-items-center gap-3"
                  style={{ backgroundColor: "#F9E79E" }}
                >
                  <div className="dropdown" style={{ cursor: "pointer" }}>
                    <UserCircleIcon
                      width={40}
                      style={{ color: "#0B0218" }}
                      data-bs-toggle="dropdown"
                    />
                    <ul
                      style={{
                        zIndex: "10000",
                        fontSize: "1rem",
                        fontFamily: "cursive",
                        overflow: "hidden",
                        padding: 0,
                      }}
                      className="dropdown-menu rounded-3 glassThree border border-2 border-secondary bg-transparent"
                    >
                      <Link to="/profile" className="text-decoration-none ">
                        <li
                          className="profileBtn text-white ps-3 p-1"
                          style={{ color: "purple" }}
                        >
                          {t("profile")}
                        </li>
                      </Link>

                      <Link to="/cashin" className="text-decoration-none">
                        <li
                          className="profileBtn text-white ps-3 py-1"
                          style={{ color: "cyan" }}
                        >
                          {t("cashIn")}
                        </li>
                      </Link>

                      <Link to="/cashout" className="text-decoration-none">
                        <li
                          className="profileBtn text-white ps-3 py-1"
                          style={{ color: "cyan" }}
                        >
                          {t("cashOut")}
                        </li>
                      </Link>

                      <Link to="/betslip" className="text-decoration-none">
                        <li
                          className="profileBtn text-white ps-3 py-1"
                          style={{ color: "cyan" }}
                        >
                          {t("betslip")}
                        </li>
                      </Link>

                      <Link
                        to="/refer_history"
                        className="text-decoration-none"
                      >
                        <li
                          className="profileBtn text-white ps-3 py-1"
                          style={{ color: "cyan" }}
                        >
                          {t("refer_history")}
                        </li>
                      </Link>

                      <div className="glassFour">
                        <Link to="/win-number" className="text-decoration-none">
                          <li
                            className="profileBtn text-white ps-3 py-1"
                            style={{ color: "cyan" }}
                          >
                            {t("winNoHistory")}
                          </li>
                        </Link>

                        <Link
                          to="/winner-list"
                          className="text-decoration-none"
                        >
                          <li
                            className="profileBtn text-white ps-3 py-1"
                            style={{ color: "cyan" }}
                          >
                            {t("winnerListHistory.title")}
                          </li>
                        </Link>
                      </div>

                      <Link
                        to=""
                        onClick={handleLogout}
                        className="text-decoration-none"
                      >
                        <li
                          className="profileBtn text-white ps-3 py-1 bg-danger"
                          style={{ color: "cyan" }}
                        >
                          {t("logout")}
                        </li>
                      </Link>
                    </ul>
                  </div>

                  <div className="me-1 text-center d-flex flex-wrap justify-content-center">
                    {/* Main Balance */}
                    <span className="d-flex align-items-start fw-bolder fs-6 fw-bold pe-md-2 text-black">
                      {Intl.NumberFormat("en-us").format(parseInt(amount))}
                      <span
                        style={{ fontSize: "0.7rem" }}
                        className="ms-2 fst-italic"
                      >
                        MMK
                      </span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="w-100 d-flex gap-3">
                  <Link
                    to="/login"
                    className="text-decoration-none d-flex align-items-center justify-content-end"
                  >
                    <button className="rounded-1 btn btn-outline-danger border-2 text-nowrap px-3 py-2 w-100">
                      <span className="fw-bolder text-uppercase fs-6">
                        {t("login")}
                      </span>
                    </button>
                  </Link>

                  <Link
                    to="/register"
                    className="text-decoration-none d-flex align-items-center justify-content-end"
                  >
                    <button className="rounded-1 btn btn-danger border-2 text-nowrap px-3 py-2 w-100 me-2">
                      <span className="fw-bolder text-uppercase fs-6">
                        {t("registerPage.register")}
                      </span>
                    </button>
                  </Link>
                </div>
              )}
            </div>

            <div
              className="d-flex justify-content-end pe-3"
              style={{ paddingLeft: "10px" }}
            >
              <div
                onClick={() => setOpenLang(!openLang)}
                className="d-flex cursor-pointer align-items-center px-2"
              >
                {language === EN ? (
                  <FlagImage width={30} flagImg={UKFlag} />
                ) : language === MM ? (
                  <FlagImage width={30} flagImg={MMFlag} />
                ) : language === CN ? (
                  <FlagImage width={30} flagImg={ChinaFlag} />
                ) : (
                  <FlagImage width={30} flagImg={ThaiFlag} />
                )}
                {/* Expand Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                </svg>
              </div>
            </div>
            {/* Land Switch: DropDown  */}
            <div
              className={`position-fixed ${
                openLang ? "d-flex justify-content-around ps-3" : "d-none"
              } flex-column fw-bold border shadow-lg border-secondary border-opacity-50 animation_down gap-1 glass text-white rounded-2 p-2`}
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                zIndex: "999",
                width: "20%",
                height: "10rem",
              }}
            >
              <div
                className={`${
                  language !== MM ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(MM)}
              >
                <FlagImage width={45} flagImg={MMFlag} />
                <span>ဗမာစာ</span>
              </div>
              <div
                className={`${
                  language !== EN ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(EN)}
              >
                <FlagImage width={45} flagImg={UKFlag} />
                <span>English</span>
              </div>
              <div
                className={`${
                  language !== THAI ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(THAI)}
              >
                <FlagImage width={45} flagImg={ThaiFlag} />
                <span>ไทย</span>
              </div>
              <div
                className={`${
                  language !== CN ? "d-flex" : "d-none"
                } gap-2 align-items-center cursor-pointer`}
                onClick={() => changeLang(CN)}
              >
                <FlagImage width={45} flagImg={ChinaFlag} />
                <span>中国人</span>
              </div>
            </div>
          </div>

          {/**  Category Bar  */}
          <div
            className="d-none border border-2 border-dark shadow border-bottom-0 border-start-0 border-end-0 pt-2 d-md-flex text-center overflow-auto justify-content-start align-items-center mt-0"
            style={{ backgroundColor: "#0B0218" }}
          >
            <div className="text-white gap-0 d-inline-flex rounded container">
              <Link
                to="/"
                role="button"
                className="d-flex align-items-center me-4 justify-content-center cursor-pointer"
              >
                <HomeIcon
                  className="text-white"
                  style={{ transform: "translateY(-15%)" }}
                  width={35}
                />
              </Link>

              {category && !loading ? (
                category.length ? (
                  category.map((cat, idx) => (
                    <div key={idx} className="catNav">
                      <div
                        key={idx}
                        onClick={() => {
                          navigate(`${cat.name}`);
                        }}
                        className={`d-flex px-3 align-items-center categoryHover justify-content-start ${
                          pathname.split("%20").join("") ===
                          cat.name.split(" ").join("")
                            ? "bg-warning text-black"
                            : ""
                        } ${idx === category.length - 1 && ""} cursor-pointer`}
                      >
                        <LazyLoadImage
                          style={{
                            width: "25px",
                            height: "25px",
                            objectFit: "cover",
                          }}
                          effect="blur"
                          src={`${BaseURL}/${cat.image}`}
                        />
                        <small className=" ms-2 text-uppercase fw-semibold">
                          {t("category." + cat.name.replace(/\s/g, ""))}
                        </small>
                      </div>
                      <div className="catDropdown animation_prov glassFour py-2 rounded-3 container">
                        {cat.category_game_providers &&
                        cat.category_game_providers.length ? (
                          cat.category_game_providers.map((a) => (
                            <div key={a.id}>
                              <Link
                                className="text-decoration-none football"
                                to={`${cat.name}/${a.id}`}
                              >
                                <div className="text-center text-white fw-bold small">
                                  <img
                                    src={`${BaseURL}/${a.image}`}
                                    className="w-100"
                                    alt="link"
                                  />
                                  <p>{a.name}</p>
                                </div>
                              </Link>
                            </div>
                          ))
                        ) : (
                          <div>Empty</div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>no category</div>
                )
              ) : (
                Array.from({ length: 5 }).map((a, idx) => (
                  <div
                    key={idx}
                    style={{ minWidth: "10rem" }}
                    className="d-flex roundedEnd gap-2 align-items-center px-3 py-2 shadow categoryHover cursor-pointer bg-secondary bg-opacity-75 "
                  >
                    <div
                      style={{ width: "20px", height: "20px" }}
                      className="bg-dark pulse bg-opacity-50 rounded-circle p-2"
                    ></div>
                    <div
                      style={{ width: "80%", height: "15px" }}
                      className="bg-dark pulse bg-opacity-50 rounded-3 p-2"
                    ></div>
                  </div>
                ))
              )}
            </div>
          </div>
        </section>
      </div>
    </nav>
  );
};

export default WebsiteHeader;
