import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDWfMkz8dZyzyWVsoJGK_drhLwC1QuL-xs",
  authDomain: "nkcrypton.firebaseapp.com",
  databaseURL: "https://nkcrypton-default-rtdb.firebaseio.com",
  projectId: "nkcrypton",
  storageBucket: "nkcrypton.appspot.com",
  messagingSenderId: "310092304568",
  appId: "1:310092304568:web:932e2a804f20a16698244d",
  measurementId: "G-L5YYG4ZHS0",
};

const app = initializeApp(firebaseConfig, "secondary");
const db = getDatabase(app);
let messaging = null;
try {
  messaging = getMessaging(app);
} catch (error) {
  console.log("Does not support current browser or be use https");
}
const database = getDatabase(app);
const vapidKey =
  "BHzjcdGdUHweUm-ZMu0zZcMtxeDNxNTkIgSXPJwCCOjW4Y4uY4ghM-gKfHxUiRsAfuzrsqLTyiUetk8FKKaWT2o";

export { ref, onValue, database, messaging, onMessage, vapidKey, getToken, db };
